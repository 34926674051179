import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import {
  Injectable,
  PLATFORM_ID,
  RendererFactory2,
  computed,
  effect,
  inject,
  signal,
} from "@angular/core";
import { ROAM_ENV_CONFIG, RoamStateInitializer } from "@roamstay/core";

export const ROAM_UI_THEME = {
  DARK: "dark",
  LIGHT: "light",
} as const;

export type RoamUiTheme = (typeof ROAM_UI_THEME)[keyof typeof ROAM_UI_THEME];

@Injectable({ providedIn: "root" })
export class UiThemeService implements RoamStateInitializer<RoamUiTheme> {
  #renderer = inject(RendererFactory2).createRenderer(null, null);
  #envConfig = inject(ROAM_ENV_CONFIG);
  #platformId = inject(PLATFORM_ID);
  #document = inject(DOCUMENT);

  #theme = signal<RoamUiTheme>("light");
  openMenu = signal<boolean>(false);
  readonly theme = this.#theme.asReadonly();
  readonly isDarkMode = computed(() => this.theme() === ROAM_UI_THEME.DARK);
  readonly isMenuOpen = computed(() => this.openMenu());

  get key() {
    return `${this.#envConfig.appId}-theme`;
  }

  get rootElement(): HTMLElement {
    return this.#document.body.parentElement as HTMLElement;
  }

  switchTheme(newTheme: RoamUiTheme): void {
    this.#theme.set(newTheme);
  }

  toggleDark(): void {
    const next = this.isDarkMode() ? ROAM_UI_THEME.LIGHT : ROAM_UI_THEME.DARK;
    this.switchTheme(next);
  }

  toggleMenu(menuOpen: boolean): void {
    const isOpen = !menuOpen;
    this.openMenu.set(isOpen);
  }

  static HAS_INITIALIZED = false;

  initialize(): void {
    if (UiThemeService.HAS_INITIALIZED) return;
    if (!isPlatformBrowser(this.#platformId)) return;
    const cached = localStorage.getItem(this.key) as RoamUiTheme | null;
    if (cached) {
      this.switchTheme(cached);
    } else {
      const selected =
        matchMedia("(prefers-color-scheme: light)").matches ?
          ROAM_UI_THEME.DARK
        : ROAM_UI_THEME.LIGHT;
      this.switchTheme(selected);
    }
  }

  protected renderTheme = effect(() => {
    const theme = this.theme();
    const forceTheme = this.#envConfig.ui?.forceTheme;
    if (!forceTheme) {
      const action = theme === ROAM_UI_THEME.DARK ? "addClass" : "removeClass";
      this.#renderer[action](this.rootElement, ROAM_UI_THEME.DARK);
      localStorage.setItem(this.key, theme);
    } else {
      const action =
        forceTheme === ROAM_UI_THEME.DARK ? "addClass" : "removeClass";
      this.#renderer[action](this.rootElement, ROAM_UI_THEME.DARK);
      localStorage.setItem(this.key, theme);
      console.warn("WARN:", `You're forcing to use ${forceTheme} theme`);
    }
  });
}
